const loadScript = src =>
  new Promise((resolve, reject) => {
    const stag = document.createElement('script');
    stag.async = true;
    stag.src = src;
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(stag, s);
    stag.addEventListener('load', resolve);
    stag.addEventListener('error', () => {
      reject(new Error('script load error'));
    });
  });

export const startTracking = async () => {
  const TRACK_ID = 'G-2845V51HJ6';
  try {
    await loadScript(`https://www.googletagmanager.com/gtag/js?id=${TRACK_ID}`);

    const dataLayer = window.dataLayer || [];
    const gtag = function gtag() {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    };
    Object.assign(window, {
      dataLayer,
      gtag,
    });

    gtag('js', new Date());
    gtag('config', TRACK_ID);

    await Promise.all([
      import('autotrack/lib/plugins/event-tracker'),
      import('autotrack/lib/plugins/outbound-link-tracker'),
      import('autotrack/lib/plugins/url-change-tracker'),
      import('autotrack/lib/plugins/max-scroll-tracker'),
    ]);
    const { ga } = window;

    ga('create', TRACK_ID, 'auto');
    ga('require', 'eventTracker');
    ga('require', 'outboundLinkTracker');
    ga('require', 'urlChangeTracker');
    ga('require', 'maxScrollTracker');
    ga('send', 'pageview');
  } catch (error) {
    console.error('load tracking error', error);
  }
};
